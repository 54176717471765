import React from "react";
import "../App.css";

function Control({evLocations, vpLocations, vpFreeLocations }) {
  return (
    <>
      <div className="controls">
        <div
          className="switch"
          onClick={() => {
            vpLocations();
          }}
        >
          von Poll
        </div>
        <div
          className="switch"
          onClick={() => {
            vpFreeLocations();
          }}
        >
          von Poll (free)
        </div>
        <div
        className="switch"
            onClick={() => {
            evLocations();
          }}
        >
          E & V
        </div>
      </div>
    </>
  );
}

export default Control;
