/*global google*/

import {
  GoogleMap,
  LoadScript,
  Marker,
  MarkerClusterer
} from "@react-google-maps/api";
import React, { useState } from "react";
import ev_loc from "../data/ev_db.json";
import vp_loc from "../data/vp_db.json";
import evcluster from "../files/clusterimg/evCluster1.png";
import vpcluster from "../files/clusterimg/vpCluster1.png";
import evicon1 from "../files/ev.svg";
import vpicon1 from "../files/vpo.svg";
import Control from "./Control";

function Map() {
  const [showEVLocations, setShowEVLocations] = useState(false);
  const [showVPLocations, setShowVPLocations] = useState(false);
  const [showVPFreeLocations, setShowVPFreeLocations] = useState(false);
  const [libraries] = useState(["visualization"]);
  const Spinner = <div class="loader"></div>;

  const center = {
    lat: 50.117133188333746,
    lng: 8.658035680214649,
  };

  const ev = ev_loc;
  const vp = vp_loc;

  const evLocations = () => {
    setShowEVLocations(!showEVLocations);
  };

  const vpLocations = () => {
    setShowVPLocations(!showVPLocations);
  };

  const vpFreeLocations = () => {
    setShowVPFreeLocations(!showVPFreeLocations);
  };

  var vpCluster = [
    {
      textColor: "#FFFFFF",
      textSize: 14,
      url: vpcluster,
      height: 50,
      width: 50,
    },
    {
      textColor: "#FFFFFF",
      textSize: 14,
      url: vpcluster,
      height: 50,
      width: 50,
    },
    {
      textColor: "#FFFFFF",
      textSize: 14,
      url: vpcluster,
      height: 50,
      width: 50,
    },
    {
      textColor: "#FFFFFF",
      textSize: 14,
      url: vpcluster,
      height: 50,
      width: 50,
    },
    {
      textColor: "#FFFFFF",
      textSize: 14,
      url: vpcluster,
      height: 50,
      width: 50,
    },
  ];

  var evCluster = [
    {
      textColor: "#FFFFFF",
      textSize: 14,
      url: evcluster,
      height: 50,
      width: 50,
    },
    {
      textColor: "#FFFFFF",
      textSize: 14,
      url: evcluster,
      height: 50,
      width: 50,
    },
    {
      textColor: "#FFFFFF",
      textSize: 14,
      url: evcluster,
      height: 50,
      width: 50,
    },
    {
      textColor: "#FFFFFF",
      textSize: 14,
      url: evcluster,
      height: 50,
      width: 50,
    },
    {
      textColor: "#FFFFFF",
      textSize: 14,
      url: evcluster,
      height: 50,
      width: 50,
    },
  ];

  const vpOptions = {
    styles: vpCluster,
  };

  const evOptions = {
    styles: evCluster,
  };

  function createKey(location) {
    return location.lat + location.lng;
  }

  return (
    <>
      <div id="dennis" className="container">
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          libraries={libraries}
          language="en"
          loadingElement={Spinner}
        >
          <GoogleMap
            id={"dennis-map"}
            mapContainerStyle={{
              width: "100%",
              height: "95%",
            }}
            center={center}
            zoom={5}
            options={{
              draggableCursor: "crosshair",
              mapTypeControl: false,
              clickable: true,
              fullScreenControl: true,
              disableDoubleClickZoom: true,
              disableDefaultUI: true,
              streetViewControl: true,
              styles: [
                { elementType: "labels", stylers: [{ visibility: "on" }] },
                { elementType: "poi", stylers: [{ visibility: "off" }] },
                {
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#898989" }],
                },
                {
                  elementType: "labels.text.stroke",
                  stylers: [{ color: "#00305E", weight: "1px" }],
                },
                {
                  featureType: "road",
                  elementType: "geometry",
                  stylers: [{ visibility: "off" }],
                },
                {
                  elementType: "geometry",
                  stylers: [
                    { invert_lightness: true },
                    { hue: "#b9d3c2" },
                    { saturation: -100 },
                    { lightness: 60 } /* generates "white" color */,
                  ],
                },
                {
                  featureType: "administrative",
                  elementType: "geometry.stroke",
                  stylers: [{ color: "#c9b2a6" }],
                },
                // {
                //   featureType: "water",
                //   elementType: "geometry.fill",
                //   stylers: [{ color: "#b9d3c2" }],
                // },
                // {
                //   featureType: "administrative.neighborhood",
                //   elementType: "labels",
                //   stylers: [{ visibility: "on" }],
                // },
                {
                  featureType: "water",
                  elementType: "geometry",
                  stylers: [{ color: "#002849" }],
                },
                {
                  featureType: "administrative.locality",
                  stylers: [{ visibility: "on" }],
                },
                {
                  featureType: "administrative.locality.fill",
                  stylers: [{ visibility: "on" }],
                },
                {
                  featureType: "administrative.locality.label",
                  stylers: [{ fontsize: "60px" }],
                },
                // {
                //   featureType: "landscape.natural",
                //   elementType: "geometry",
                //   stylers: [{ color: "#dfd2ae" }],
                // },
                {
                  featureType: "road",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "road.arterial",
                  elementType: "geometry",
                  stylers: [{ visibility: "off", color: "#CCFFFF" }],
                },
                {
                  featureType: "landscape",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
              ],
            }}
          >
            <Control
              vpLocations={vpLocations}
              vpFreeLocations={vpFreeLocations}
              evLocations={evLocations}
            />
            {showVPLocations ? (
              <MarkerClusterer
                options={vpOptions}
                minimumClusterSize={2}
                averageCenter={true}
              >
                {(clusterer) =>
                  vp.map((item, index) =>
                    item.type !== "Free" ? (
                      <Marker
                        icon={{
                          url: vpicon1,
                          fillColor: "hotpink",
                          fillOpacity: 1,
                          width: 50,
                          height: 50,
                          scaledSize: new google.maps.Size(37, 25),
                        }}
                        key={createKey(item)}
                        position={item}
                        clusterer={clusterer}
                      ></Marker>
                    ) : null
                  )
                }
              </MarkerClusterer>
            ) : null}
            {showVPFreeLocations ? (
              <MarkerClusterer
                options={vpOptions}
                minimumClusterSize={2}
                averageCenter={true}
              >
                {(clusterer) =>
                  vp.map((item, index) =>
                    item.type === "Free" ? (
                      <Marker
                        icon={{
                          url: vpicon1,
                          scaledSize: new google.maps.Size(40, 25),
                        }}
                        key={createKey(item)}
                        position={item}
                        clusterer={clusterer}
                      />
                    ) : null
                  )
                }
              </MarkerClusterer>
            ) : null}
            {showEVLocations ? (
              <MarkerClusterer
                options={evOptions}
                minimumClusterSize={2}
                averageCenter={true}
              >
                {(clusterer) =>
                  ev.map((item, index) => (
                    <Marker
                      icon={{
                        url: evicon1,
                        scaledSize: new google.maps.Size(40, 20),
                      }}
                      key={createKey(item.geo)}
                      position={item.geo}
                      clusterer={clusterer}
                    ></Marker>
                  ))
                }
              </MarkerClusterer>
            ) : null}
          </GoogleMap>
        </LoadScript>
      </div>
    </>
  );
}

export default Map;
