import React, { Fragment, useLayoutEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header.js";
import Map from "./components/Map.js";
//import iwg from "./files/iwg.png";
import userid from "./data/login.json";
//import branding from "./components/Gallery.js";
import logo from "./files/vpo_logo_gold.svg"

export default function App() {
  const [login, setLogin] = useState();

  
  useLayoutEffect(() => {
    const login1 = window.sessionStorage.getItem("iwgapplogin");
    if (login1 === "true") {
      setLogin(true);
    }
  }, []);

  function sessionLogin(e) {
    e.preventDefault();

    const data = userid.filter((obj) => {
      return obj.user === e.target[0].value;
    });
    const s = window.sessionStorage;

    data[0] === undefined
      ? alert("Something went wrong. Please try again.")
      : process();

    function process() {
      if (
        data[0].user === e.target[0].value &&
        data[0].pw === e.target[1].value
      ) {
        setLogin(true);
        s.setItem('iwgapplogin', 'true')

      } else {
        alert("Wrong username or password");
      }
    }
  }

  return (
    <>
      {login ? null : (
        <div className="modal">
          <form className="modal-input-container" onSubmit={sessionLogin}>
            <img className="modal-logo" alt="vplogo" src={logo} />
            <input
              placeholder="Your email..."
              className="modal-input"
              type="text"
            />
            <input
              placeholder="Password"
              className="modal-input"
              type="password"
            />
            <button className="button-class">
              <span className="text">Login</span>
            </button>
          </form>
        </div>
      )}
      {login ? (
        <Router>
          <Header />
          <Fragment>
            <main>
              <Routes>
                <Route exact path="/" element={<Map />} />
              </Routes>
            </main>
          </Fragment>
        </Router>
      ) : null}
    </>
  );
}
