import { React } from "react";
import "../App.css";
import vpicon1 from "../files/vpo_logo.svg";


function Header() {
  return (
    <header>
      <div className="headline">
        <img className="logo" alt="logo" src={vpicon1} />
        <h2>Franchise Data Room</h2>
      </div>
    </header>
  );
}

export default Header;
